import React from "react"

export default () => (
	<div id="social" className="text-center panel">
    <div className="panel-content">
      <h2 className="text-center">Social</h2>
      <a className="twitter-timeline" href="https://twitter.com/HoopleWebDesign" data-widget-id="346947227312345088">Tweets by @HoopleWebDesign</a>
    </div>
  </div>
)
