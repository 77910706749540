import React from "react"
import * as PropTypes from "prop-types"
import { transformToList, createButton } from "../utils/ContentUtils"

export const ProjectDefinitionItem = ({
  projectTitle,
  value,
  isMarkdown,
  isComponent,
  className,
}) => (
  <>
    {projectTitle && <dt className="heading-4 mt-4 first:mt-0">{projectTitle}</dt>}

    {isMarkdown && (
      <dd className={`leading-normal content`}>
        <div
          className={`${className}`}
          dangerouslySetInnerHTML={{ __html: value }}
        ></div>
      </dd>
    )}
    {!isMarkdown && !isComponent && (
      <dd className={`leading-normal content`}>{value}</dd>
    )}

    {isComponent && (
      <dd className={`leading-normal content`}>{<>{value}</>}</dd>
    )}
  </>
)

ProjectDefinitionItem.propTypes = {
  isMarkdown: PropTypes.bool.isRequired,
  isComponent: PropTypes.bool.isRequired,
  projectTitle: PropTypes.string,
  projectlink: PropTypes.string,
  year: PropTypes.number,
  description: PropTypes.object,
  image: PropTypes.string,
  className: PropTypes.string,
  technology: PropTypes.string,
}

ProjectDefinitionItem.defaultProps = {
  className: ``,
  isMarkdown: true,
  isComponent: false,
}

const ProjectCard = ({
  projectTitle,
  projectlink,
  description,
  year,
  image,
  technology
}) => {

const technologyList = technology
? transformToList(technology, `technology`, `technology`)
: null

const websiteButton = projectlink
? createButton(projectlink, `btn btn-blue`)
: null

  return (
    <div
      className={`flex flex-col w-full h-full bg-white p-6 project-card`}
    >
      <div className="flex flex-wrap flex-shrink-0 flex-grow-0 border-b border-silver pb-6 mb-6 -mt-2">
        <h3 className={`flex-shrink flex-grow heading-3 my-2 mr-2 w-3/4 font-bold`}>
          {projectTitle} / {year}
        </h3>
      </div>
      <dl className="flex-shrink-0 flex-grow border-b border-silver pb-6 mb-6">
      <img src={image} className="object-cover w-full"></img>
      </dl>
      <dl className="flex-shrink-0 flex-grow border-b border-silver pb-6 mb-6">
      {description.description}
      </dl>
      <h3 className={`flex-shrink flex-grow heading-3 my-2 mr-2 w-3/4 font-bold`}>
          Technology used
        </h3>
      <dl className="flex-shrink-0 flex-grow border-b border-silver pb-6 mb-6">
        <ProjectDefinitionItem
          title={`Technology`}
          value={technologyList}
          isMarkdown={false}
          isComponent={false}
        />
      </dl>
      <dl className="flex-shrink-0 flex-grow">
        <ProjectDefinitionItem
          value={websiteButton}
          isMarkdown={false}
          isComponent={false}
        />
      </dl>
      <div className="flex-shrink-0 flex-grow-0 mt-6">
      </div>
    </div>
  )
}

ProjectCard.propTypes = {
  projectTitle: PropTypes.string,
  projectlink: PropTypes.string,
  description: PropTypes.object,
  year: PropTypes.number,
  image: PropTypes.string,
  technology: PropTypes.arrayOf(PropTypes.string),
}

export default ProjectCard
