import React from "react"

export default () => (

	<div id="services" className="text-center panel">
		<div className="panel-content">
			<h2 className="text-center">Services</h2>
			<p>Express Node.js web applications</p>
			<p>React web applications</p>
			<p>Static websites</p>
			<p>Websites built on WordPress</p>
			<p>eCommerce websites built using WooCommerce and BigCommerce</p>
			<p>Email newsletters</p>
			<p>Updating your current website</p>
			<p>SEO reports</p>
		</div>
</div>
)
