import React from "react"
import * as PropTypes from "prop-types"
import SecondaryCallToAction from "./SecondaryCallToAction"

const ProjectsSecondaryCallToAction = ({
  text,
  to,
  openInNewWindow,
  backgroundColorClassName,
}) => (
  <SecondaryCallToAction
    underlineColorClassName={`border-major-work-events-yellow`}
    underlineHoverColorClassName={`border-major-work-events-black`}
    text={text}
    textColorClassName={`text-major-work-events-black`}
    to={to}
    backgroundColorClassName={backgroundColorClassName}
    openInNewTab={openInNewWindow}
  />
)

ProjectsSecondaryCallToAction.propTypes = {
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  openInNewTab: PropTypes.bool,
  backgroundColorClassName: PropTypes.string,
}

ProjectsSecondaryCallToAction.defaultProps = {
  openInNewTab: false,
  backgroundColorClassName: `bg-white`,
}

export default ProjectsSecondaryCallToAction
