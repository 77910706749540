import React from "react"

export default () => (

	<div id="contact" className='text-center panel'>
		<div className="panel-content">
			<h2 className="text-center">Contact</h2>
					<form name='websiteContact' method='POST' data-netlify='true' data-netlify-honeypot='bot-field'>
						<input type='hidden' name='form-name' value='websiteContact' />
						<label>Your name</label>
						<input type='text' name='name' placeholder='your name'></input>
						<label>Your email</label>
						<input type='email' name='email' placeholder='your email'></input>
						<label>Your query</label>
						<textarea name='query' placeholder='your query'></textarea>
						<button className="btn btn-blue" type='submit'>send</button>
					</form>
		</div>
	</div>
)
