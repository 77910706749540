import moment from "moment"

// set start of week to Monday, moment defaults to Sunday
moment.updateLocale("en", {
  week: {
    dow: 1,
  },
})

export const EXTRACT_ALL_PROJECTS = (projects, limit = 8) => {
  return projects
    .slice(0, limit)
}

/* export const EXTRACT_TODAY = (disruptions, limit = 6) => {
  return disruptions
    .filter(disruption => {
      const { startDate } = disruption.disruption
      const start = moment(startDate)

      return start.isSame(moment(), "day") && start.isSame(moment(), "date")
    })
    .slice(0, limit)
}

export const EXTRACT_THIS_WEEK = (disruptions, limit = 6) => {
  const startOfWeek = moment().startOf("week")
  const endOfWeek = moment().endOf("week")

  return disruptions
    .filter(disruption => {
      const { startDate, endDate } = disruption.disruption

      const start = moment(startDate)
      const end = moment(endDate)
      return start.isSameOrBefore(endOfWeek) && startOfWeek.isSameOrBefore(end)
    })
    .slice(0, limit)
}

export const EXTRACT_NEXT_WEEK = (disruptions, limit = 6) => {
  const startOfNextWeek = moment()
    .startOf("week")
    .add(1, "week")
  const endOfNextWeek = moment()
    .startOf("week")
    .add(1, "week")
    .add(6, "days")

  return disruptions
    .filter(disruption => {
      const { startDate, endDate } = disruption.disruption

      const start = moment(startDate)
      const end = moment(endDate)

      return (
        start.isSameOrBefore(endOfNextWeek) &&
        startOfNextWeek.isSameOrBefore(end)
      )
    })
    .slice(0, limit)
}

export const EXTRACT_THIS_MONTH = (disruptions, limit = 6) => {
  const startOfMonth = moment().startOf("month")
  const endOfMonth = moment().endOf("month")

  return disruptions
    .filter(disruption => {
      const { startDate, endDate } = disruption.disruption

      const start = moment(startDate)
      const end = moment(endDate)

      return (
        start.isSameOrBefore(endOfMonth) && startOfMonth.isSameOrBefore(end)
      )
    })
    .slice(0, limit)
}

export const FORMAT_EVENT_DATE = (start, end) => {
  if (end) {
    const startMoment = moment(start)
    const endMoment = moment(end)

    const startTs = startMoment.format(`dddd D MMMM YYYY`)
    const endTs = endMoment.format(`dddd D MMMM YYYY`)

    // display date and start and end time:
    if (
      startMoment.isSame(endMoment, "date") &&
      startMoment.isSame(endMoment, "day")
    ) {
      return `${moment(start).format(`dddd D MMMM YYYY`)} from ${moment(
        start
      ).format(`h:mma`)} to ${moment(end).format(`h:mma`)}`
    }

    return `${startTs} to ${endTs}`
  }

  // just return start date:
  return moment(start).format(`dddd D MMMM YYYY, h:mma`)
}

export const IS_ACTIVE = (start, end) => {
  return true
} */
