import React from "react"

export const transformToList = (data, id, className) => {
  return (
    <div className={`${className}`}>
      <ul>
        {data.map((item, idx) => (
          <li key={`${id}-${idx}`}>{item}</li>
        ))}
      </ul>
    </div>
  )
}

export const GENERATE_KEY = value => {
  return value.replace(/\s/g, "").toLowerCase()
}

export const createButton = (projectlink, className) => {
  return (
    <button className={`${className}`}>
        <a href={projectlink} target="_blank">Visit website</a>
    </button>
  )
}
