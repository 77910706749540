import * as PropTypes from "prop-types"

const IsInternalLink = link => {
  return link.charAt(0) === "/"
}

IsInternalLink.propTypes = {
  link: PropTypes.string.isRequired,
}

export default IsInternalLink
