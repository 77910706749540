import React from "react"
import { graphql } from "gatsby"
import {
  EXTRACT_ALL_PROJECTS
} from "../utils/DateUtils"
import ProjectsPanel from "../components/ProjectsPanel"
import Layout from "../components/Layout"
import { GENERATE_KEY } from "../utils/ContentUtils"
import Services from "../components/Services"
import Form from "../components/form"
import Twitter from "../components/twitter"

const IndexPage = ({ data }) => {

  const allProjects = data.allContentfulProjects.edges.reduce((projects, { node }) => {
    const { projectTitle, projectlink, year, description, image, technology } = node
    const key = GENERATE_KEY(projectTitle)

      projects.push({
        project: {
          key,
          projectTitle,
          projectlink,
          year,
          description,
          image: image 
            ? image.file.url : ``,
          technology,
        },
      })

    return projects
  }, [])
  
  const getallProjects = EXTRACT_ALL_PROJECTS(allProjects)

  return (
    <Layout className={`font-sans bg-white`}>

        <div id="about" className='text-center panel'>
          <div className="panel-content">
            {data.allContentfulPage.edges.map(({ node }) => (
                <div key={node.id}>
                  <h2 className="text-center">{node.title}</h2>
                  <p>{node.content.content}</p>
                </div>
            ))}
          </div>
        </div>
        
          <Services />

        {(
          <ProjectsPanel
            projects={getallProjects}
            title={`Projects`}
            showViewAll={true}
            visible={7}
          />
        )}

          <Form />

          <Twitter />

    </Layout>
  )
}

export const landingPageQuery = graphql`
  query landingPageQuery {
      allContentfulProjects(sort: { fields: [year], order: [DESC] }) {
        edges {
          node {
            projectTitle
            projectlink
            year
            description {
              description
            }
            technology
            image {
              file {
                fileName
                url
              }
            }
          }
        }
      }
      allContentfulPage(filter: {title: {eq: "About me"}}) {
        edges {
          node {
            id
            title
            content {
              content
            }
          }
        }
      }
  }
`

export default IndexPage
