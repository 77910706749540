import React from "react"
import { Link } from "gatsby"
import * as PropTypes from "prop-types"
import IsInternalLink from "../utils/IsInternalLink"

const CTABody = ({ textColorClassName, text }) => (
  <>
    <span>{text}</span>
    <span className="ml-2" style={{ width: "14px", height: "11px" }}>
      <ArrowRight colorClassName={textColorClassName} />
    </span>
  </>
)

const SecondaryCallToAction = ({
  to,
  text,
  backgroundColorClassName,
  textColorClassName,
  underlineColorClassName,
  underlineHoverColorClassName,
  openInNewTab,
}) => {
  const className = `${backgroundColorClassName} ${textColorClassName} border-b-2 ${underlineColorClassName} hover:opacity-75 hover:${underlineHoverColorClassName} focus:opacity-75 focus:${underlineHoverColorClassName} inline-flex items-center`

  if (IsInternalLink(to)) {
    return (
      <Link
        to={to}
        className={className}
        target={openInNewTab ? "_blank" : "_self"}
      >
        <CTABody textColorClassName={textColorClassName} text={text} />
      </Link>
    )
  }

  return (
    <a
      className={className}
      href={to}
      target={openInNewTab ? "_blank" : "_self"}
    >
      <CTABody textColorClassName={textColorClassName} text={text} />
    </a>
  )
}

SecondaryCallToAction.propTypes = {
  backgroundColorClassName: PropTypes.string.isRequired,
  textColorClassName: PropTypes.string.isRequired,
  underlineColorClassName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  openInNewTab: PropTypes.bool,
}

SecondaryCallToAction.defaultProps = {
  openInNewTab: false,
}

export default SecondaryCallToAction
