import React, { useState } from "react"
import * as PropTypes from "prop-types"
import ProjectsSecondaryCallToAction from "./ProjectsSecondaryCallToAction"
import ProjectCard from "./ProjectCard"

const ProjectsPanel = ({
  title,
  showMoreLink,
  projects,
  visible,
  showViewAll,
  loadMoreButtonTitle,
  className,
}) => {

  const [loadMore, setLoadMore] = useState(showMoreLink)
  const [projectsOnDisplay, setprojectsOnDisplay] = useState(projects)

  // limit if -1 means show all:
  const [limit, setLimit] = useState(
    visible === -1 ? (projects ? projects.length : 0) : visible
  )
  const [viewAll, setViewAll] = useState(showViewAll)

  const loadMoreHandler = () => {
    setLimit(prev => prev + visible)
    setLoadMore(projects.length - limit <= 1 ? false : true)
  }

  return (
    <div id="projects" className="panel">
      <div className="panel-content">
        <h2 className="text-center">{title}</h2>
        <div className={`mt-6`}>
          <div className={`flex flex-wrap md:-ml-4`}>
            {projectsOnDisplay &&
              projectsOnDisplay
                .slice(0, limit)
                .map(({ project }, idx) => (
                  <div
                    key={`disruption-panel-${project.key}-${idx}`}
                    className={`w-full md:w-1/2 lg:w-1/3 flex-shrink-0 flex-grow-0 mb-4 md:pl-4`}
                  >
                    <ProjectCard
                      projectTitle={project.projectTitle}
                      projectlink={project.projectlink}
                      year={project.year}
                      description={project.description}
                      image={project.image}
                      technology={project.technology}
                    />
                  </div>
                ))}
          </div>
        </div>
      </div>

      {loadMore && (
        <div className={`flex-shrink-0 flex-grow-0 mt-6`}>
          <Button
            onClickHandler={loadMoreHandler}
            className={`bg-major-work-events-black text-white`}
            text={loadMoreButtonTitle}
          />
        </div>
      )}
    </div>
  )
}

ProjectsPanel.propTypes = {
  projectTitle: PropTypes.string,
  projectlink: PropTypes.string,
  description: PropTypes.object,
  year: PropTypes.number,
  className: PropTypes.string,
  image: PropTypes.string,
  technology: PropTypes.string,
}

ProjectsPanel.defaultProps = {
  visible: 4,
  className: `mt-6`,
}

export default ProjectsPanel
